import { ConfigurableProduct } from '@magentoTypes'
import { useCallback } from 'react'
import { getMinimumPrice } from '~/lib/getMinimumPrice'
import { useProductTracking } from '~/lib/tracking/useProductTracking'

export const useProductCardSendEvent = (
  hoveredIndex: number,
  isLoading: boolean,
  product: ConfigurableProduct,
  productPrice?: ConfigurableProduct,
  relatedProduct?: ConfigurableProduct,
) => {
  const { triggerProductEvent } = useProductTracking()

  const onSendDataLayer = useCallback(
    (eventName: string) => {
      const relatedProductPrice =
        hoveredIndex !== 0 && !isLoading && relatedProduct
          ? getMinimumPrice(relatedProduct.price_range?.minimum_price)
          : undefined

      const price = productPrice
        ? getMinimumPrice(productPrice.price_range.minimum_price)
        : getMinimumPrice(product.price_range.minimum_price)
      triggerProductEvent(eventName, relatedProduct || product, relatedProductPrice || price)
    },
    [hoveredIndex, isLoading, product, productPrice, relatedProduct, triggerProductEvent],
  )

  return { onSendDataLayer }
}
