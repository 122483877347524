import { useElementSize } from '@charlietango/hooks'
import { ConfigurableProduct, ProductInterface, PriceRange } from '@magentoTypes'
import { useEffect, useMemo, useRef, useState, useCallback } from 'react'
import { AddToWishlist } from '~/elements/AddToWishlist/AddToWishlist'
import { Link } from '~/elements/Link/Link'
import { ColorThumbs } from './components/ColorThumbs'
import { useRouter } from 'next/router'
import { useBrandType } from '~/hooks/useBrandType'
import clsx from 'clsx'
import ProductCardBadges from '~/modules/ProductCard/components/ProductCardBadges'
import ProductCardTop from '~/modules/ProductCard/components/ProductCardTop'
import FeaturesSection from '~/elements/FeaturesSection/FeaturesSection'
import PriceSection from '~/elements/PriceSection/PriceSection'
import SampleButton from '~/modules/ProductCard/components/SampleButton'
import Loader from '~/modules/ProductCard/components/Loader'
import { getCioProductPrice, getCioVariationId } from '~/lib/cioHelpers'
import { RatingStars } from '~/elements/RatingStars/RatingStars'
import { useInView } from 'react-intersection-observer'
import { useProductFeatures } from '~/hooks/useProductFeatures'
import { useProductCardDetails } from '~/modules/ProductCard/hooks/useProductCardDetails'
import { useProductCardSendEvent } from '~/modules/ProductCard/hooks/useProductCardSendEvent'
import { hasSalePrice } from '~/lib/productBadgeChecks'
import { useGenerateAltTags } from '~/modules/ProductCard/hooks/useGenerateAltTags'
import { mapProductDetails } from '~/modules/ProductCard/mappers/mapProductDetails'

type Props = {
  product: ConfigurableProduct
  productPrice?: ConfigurableProduct
  hasPriority?: boolean
  initialInView?: boolean
  className?: string
  colCount?: number
  isSlider?: boolean
  showSampleCta?: boolean
  deferHydration?: boolean
  cartId?: string
  withCioAttributes?: boolean
  cioItemType?: string
  cioStrategyId?: string
  urlSelectedSize?: string
  imageLoadStrategy?: 'lazy' | 'eager'
  hidePriceFromSuffix?: boolean
  getImageHeight?: (val: number) => void
  onSampleAddedToCart?: () => void
  cioForceShowVariation?: boolean
  useCio?: boolean
}

export const ProductCard = ({
  product,
  productPrice,
  hasPriority,
  initialInView = false,
  className,
  getImageHeight,
  isSlider,
  showSampleCta,
  deferHydration,
  cartId,
  onSampleAddedToCart,
  withCioAttributes,
  cioItemType,
  cioStrategyId,
  urlSelectedSize,
  imageLoadStrategy,
  hidePriceFromSuffix,
  cioForceShowVariation,
  useCio,
}: Props) => {
  const router = useRouter()
  const [isHydrated, setIsHydrated] = useState(!!!deferHydration)
  const nameRef = useRef<HTMLButtonElement>(null)
  const [hoveredIndex, setHoveredIndex] = useState(0)
  const [_, { height: imgHeight }] = useElementSize()
  const [ref, inView] = useInView({
    initialInView,
    threshold: 0,
    rootMargin: '50px',
    triggerOnce: true,
  })
  const { relatedProductData, isLoading } = useProductCardDetails(
    product,
    hoveredIndex,
    !!useCio,
    !!cioForceShowVariation,
  )
  const { generateAltTags } = useGenerateAltTags()

  const relatedProducts = useMemo(() => {
    return (product?.related_products ?? []).filter(
      (elem): elem is ProductInterface =>
        elem != null &&
        elem.benuta_form_new === product?.benuta_form_new &&
        elem.stock_status !== 'OUT_OF_STOCK',
    )
  }, [product])

  const relatedProduct = useMemo(() => {
    return relatedProductData?.products?.items?.[0] as ConfigurableProduct | undefined
  }, [relatedProductData])

  const { onSendDataLayer } = useProductCardSendEvent(
    hoveredIndex,
    isLoading,
    product,
    productPrice,
    relatedProduct,
  )

  const handleColorSelect = (index: number) => {
    setHoveredIndex(index)
  }

  const cioVariationId = useMemo(() => {
    return getCioVariationId(product)
  }, [product])

  const cioProductPrice = useMemo(() => {
    return getCioProductPrice(productPrice?.price_range || product.price_range)
  }, [product, productPrice])

  const isFirstProduct = hoveredIndex === 0
  const firstElementOrLoading = isFirstProduct || (hoveredIndex !== 0 && isLoading)

  const activeProduct = useMemo(() => {
    return firstElementOrLoading ? product : relatedProduct
  }, [firstElementOrLoading, product, relatedProduct])

  const getProductAttribute = useCallback(
    (attributeKey: keyof ConfigurableProduct) => {
      return activeProduct?.[attributeKey]
    },
    [activeProduct],
  )

  const { featureList, isAnyFeature } = useProductFeatures({
    sealId: getProductAttribute('benuta_test_seal') as string,
    productionTypeId: getProductAttribute('benuta_production_type') as string,
    pileMaterialId: getProductAttribute('benuta_pile_material') as number,
    waschbarId: getProductAttribute('benuta_waschbar') as number,
  })

  const hasAR = useMemo(
    () => (firstElementOrLoading ? product?.benuta_ar : relatedProduct?.benuta_ar),
    [firstElementOrLoading, product?.benuta_ar, relatedProduct?.benuta_ar],
  )

  const backgroundImage = useMemo(() => {
    if (!product) return null

    const images = product?.media_gallery
      ?.filter((item) => !item?.disabled)
      .find((item) => item?.position === 1)
    return images
  }, [product])

  const getBackgroundImage = useMemo(() => {
    if (isFirstProduct) {
      return backgroundImage?.url
    }

    if (isLoading) {
      return '/images/fallback_product.avif'
    }

    const galleryIndex = activeProduct?.media_gallery?.findIndex((el) => el?.position === 1)

    if (galleryIndex !== undefined && galleryIndex >= 0) {
      return activeProduct?.media_gallery?.[galleryIndex]?.url
    }

    return null
  }, [isFirstProduct, isLoading, activeProduct, backgroundImage])

  const mainImages = useMemo(() => {
    return [
      product?.image?.url,
      ...(product?.related_products?.map((related) => related?.image?.url) || []),
    ]
  }, [product])

  const getMainImage = useMemo(() => {
    const currentImage = mainImages?.[hoveredIndex]

    if (currentImage?.includes('placeholder')) {
      // TODO: change fallback image
      return '/images/fallback_product.avif'
    }

    return currentImage
  }, [mainImages, hoveredIndex])

  useEffect(() => {
    getImageHeight?.(imgHeight)
  }, [imgHeight, getImageHeight])

  useEffect(() => {
    if (router.isReady && !isHydrated && productPrice) setIsHydrated(true)
  }, [router.isReady, productPrice, isHydrated])

  const brandType = useBrandType({ manufacturer: product?.manufacturer as number })

  const isSalePrice = useMemo(() => {
    const productToCheck = firstElementOrLoading ? productPrice || product : activeProduct

    return hasSalePrice({
      price_range: productToCheck?.price_range as PriceRange,
    })
  }, [firstElementOrLoading, productPrice, product, activeProduct])

  const finalProductUrl = useMemo(() => {
    if (isLoading) return product?.url_key

    return getProductAttribute('url_key') || product?.url_key
  }, [isLoading, product, getProductAttribute])

  const productDetails = useMemo(() => {
    return mapProductDetails(
      activeProduct ?? product,
      isSalePrice,
      finalProductUrl,
      getMainImage,
      generateAltTags(activeProduct as ConfigurableProduct),
      urlSelectedSize,
      hasAR,
      getBackgroundImage,
    )
  }, [
    product,
    hasAR,
    isSalePrice,
    activeProduct,
    finalProductUrl,
    generateAltTags,
    getBackgroundImage,
    getMainImage,
    urlSelectedSize,
  ])

  return (
    <figure
      id={product?.url_key as string}
      className={clsx('relative h-full w-full', className)}
      data-cnstrc-item={withCioAttributes && cioItemType ? cioItemType : undefined}
      data-cnstrc-item-id={withCioAttributes ? product?.sku : undefined}
      data-cnstrc-item-name={withCioAttributes ? product?.name : undefined}
      data-cnstrc-item-variation-id={withCioAttributes ? cioVariationId : undefined}
      data-cnstrc-item-price={withCioAttributes ? cioProductPrice : undefined}
      data-cnstrc-strategy-id={withCioAttributes ? cioStrategyId : undefined}
    >
      <div
        ref={ref}
        className="pointer-events-none absolute top-[-100%] bottom-[0] z-[-1] w-[1px] bg-none"
      />
      <ProductCardTop
        backgroundImage={productDetails.backgroundImage as string}
        dataLayerEvent={() => onSendDataLayer('select_item')}
        alt={generateAltTags(product)}
        productUrl={productDetails.productUrl}
        productName={productDetails.productName || ''}
        mainImage={productDetails.mainImage as string}
        imageLoadStrategy={imageLoadStrategy}
        isSlider={isSlider}
        inView={inView}
        hasAR={hasAR}
      />
      <div className="w-full pb-[15px] pt-2.5">
        <figcaption className="relative flex flex-col items-start gap-2.5">
          {inView && (
            <div className="flex w-full justify-between">
              <div className="min-h-[28px] lgx:min-h-[40px]">
                <ColorThumbs
                  product={product}
                  relatedProducts={relatedProducts}
                  selectedColorIndex={hoveredIndex}
                  onColorSelect={handleColorSelect}
                />
              </div>
              <div className="right-0 top-0 h-6 min-h-[24px] w-6 min-w-[24px]">
                <AddToWishlist
                  isProductCard={true}
                  trackingHandler={onSendDataLayer}
                  sku={productDetails.sku as string}
                  withCioAttributes
                />
              </div>
            </div>
          )}
          <div className="flex w-full flex-col items-start gap-2.5">
            {productDetails.hasBadges && hoveredIndex !== 0 && isLoading ? (
              <Loader height={24} />
            ) : (
              productDetails.hasBadges && (
                <ProductCardBadges
                  newBadge={productDetails.newProduct && !productDetails.isSale}
                  saleBadge={productDetails.isSale}
                  bestsellerBadge={productDetails.hasBestseller}
                />
              )
            )}
            <button
              className="w-full max-w-full grow pr-1"
              onClick={() => onSendDataLayer('select_item')}
              ref={nameRef}
            >
              <Link
                href={productDetails.productUrl}
                title={productDetails.productName as string}
                className="block max-w-full"
              >
                {isLoading && hoveredIndex !== 0 ? (
                  <Loader height={nameRef.current?.offsetHeight || 0} />
                ) : (
                  <p className="px-[2px] text-left text-[15px] font-extralight leading-[18px] md:px-0 lg:text-15-22-sg">
                    {brandType && (
                      <span className="text-[15px] leading-[18px] text-sg-dark-grey lg:text-15-22-sg">
                        {brandType.name}{' '}
                      </span>
                    )}
                    {productDetails.productName}
                  </p>
                )}
              </Link>
            </button>
            {isLoading && hoveredIndex !== 0 && isAnyFeature ? (
              <div className="h-[14px] w-full lg:h-[22px]">
                <Loader height={'100%'} />
              </div>
            ) : (
              isAnyFeature && (
                <FeaturesSection
                  isWolle={featureList.isWolle}
                  isCotton={featureList.isCotton}
                  hasSustainability={featureList.hasSustainability}
                  isHandmade={featureList.isHandmade}
                  isWashable={featureList.isWashable}
                />
              )
            )}
            {!showSampleCta && (
              <>
                {isHydrated &&
                  !productDetails.isSoldOut &&
                  (hoveredIndex !== 0 && isLoading ? (
                    <Loader height={22} />
                  ) : (
                    <PriceSection
                      hasSale={productDetails.isSale}
                      hideFromSuffix={hidePriceFromSuffix}
                      productPrice={hoveredIndex !== 0 ? undefined : productPrice?.price_range}
                      product={
                        hoveredIndex !== 0 ? (relatedProduct as ConfigurableProduct) : product
                      }
                    />
                  ))}
              </>
            )}
          </div>
          {!showSampleCta && product.reviews_io_score && (
            <div className="min-h-[24px]">
              {inView && <RatingStars rating={product.reviews_io_score} />}
            </div>
          )}
          {showSampleCta && cartId && (
            <SampleButton
              product={product}
              cartId={cartId}
              onSampleAddedToCart={onSampleAddedToCart}
            />
          )}
        </figcaption>
      </div>
    </figure>
  )
}
