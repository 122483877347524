import { ConfigurableProduct } from '@magentoTypes'
import { hasBestseller, isNewProduct } from '~/lib/productBadgeChecks'

export const mapProductDetails = (
  product: ConfigurableProduct,
  hasSalePrice: boolean,
  finalProductUrl: unknown,
  mainImage: unknown,
  altTags: string,
  urlSelectedSize?: string,
  hasAR?: string | null,
  backgroundImage?: string | null,
) => {
  const hasBadges =
    hasSalePrice ||
    isNewProduct(product?.created_at as string) ||
    hasBestseller(product.umsatz as number)
  return {
    isSale: hasSalePrice,
    newProduct: isNewProduct(product?.created_at as string),
    hasAR,
    hasBestseller: hasBestseller(product.umsatz as number),
    hasBadges: hasBadges,
    isSoldOut: product.stock_status === 'OUT_OF_STOCK',
    backgroundImage: backgroundImage,
    productName: product.name as string,
    productUrl: `${finalProductUrl as string}.html${
      urlSelectedSize ? `?selected_size=${urlSelectedSize}` : ''
    }#${product?.uid}`,
    sku: product.sku as string,
    mainImage,
    altForImage: altTags,
  }
}
