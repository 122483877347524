import { ConfigurableProduct } from '@magentoTypes'
import { useCioGetItemById } from './useCioGetItemById'
import { useMagentoGetItemById } from './useMagentoGetItemById'

export const useProductCardDetails = (
  product: ConfigurableProduct,
  currentProductIndex: number,
  useCio: boolean,
  cioForceShowVariation: boolean,
) => {
  const relatedProductSkus = [
    product?.sku,
    ...(product?.related_products?.map((related) => related?.sku) || []),
  ]
  const itemId = relatedProductSkus?.[currentProductIndex] as string
  const { getCioItemById } = useCioGetItemById(
    itemId,
    cioForceShowVariation,
    currentProductIndex !== 0 && useCio,
  )
  const { getMagentoItemById } = useMagentoGetItemById(itemId, currentProductIndex, !useCio)

  if (!useCio) {
    const { magentoRelatedProductData, isMagentoDataLoading } = getMagentoItemById()
    return { relatedProductData: magentoRelatedProductData, isLoading: isMagentoDataLoading }
  } else {
    const { cioRelatedProductData, isCioDataLoading } = getCioItemById()
    return { relatedProductData: cioRelatedProductData, isLoading: isCioDataLoading }
  }
}
