import { ConfigurableProduct, ProductInterface, ProductStockStatus } from '@magentoTypes'
import { RecommendationsResultType, Result } from '@constructor-io/constructorio-client-javascript'
import { mapPrice } from './mapPrice'

const mapRelatedProducts = (relatedProducts: any[]): ProductInterface[] => {
  return relatedProducts.map(
    (item: any) =>
      ({
        id: 0,
        uid: item?.uid,
        sku: item?.sku,
        name: item?.name,
        small_image: {
          url: item?.image_url,
        },
        thumbnail: {
          url: item?.image_url,
        },
        image: {
          url: item?.image_url,
        },
        url_key: item?.url,
        // FROM CIO we only get values of "TRUE"/"FALSE", therefore this weird check
        stock_status:
          item.in_stock.toUpperCase() === 'TRUE'
            ? ProductStockStatus.InStock
            : ProductStockStatus.OutOfStock,
        benuta_form_new: item?.benuta_form_new_id ? Number(item?.benuta_form_new_id) : null,
      } as ProductInterface),
  )
}

export const mapProduct = (
  result: Result | Partial<RecommendationsResultType>,
  showVariation: boolean = false,
  forcedVariationIndex?: number,
): ConfigurableProduct => {
  const selectedVariation =
    showVariation && forcedVariationIndex !== undefined
      ? result?.variations?.[forcedVariationIndex]
      : undefined

  return {
    id: 0, // TODO: check if this is has any impact
    sku: result.data?.id,
    uid: result.data?.parent_uid || '',
    name: showVariation ? result.value : result.data?.parent_name || result.value,
    image: {
      url: result.data?.parent_image_url || result.data?.image_url,
      label: result.data?.parent_name || result.value,
    },
    media_gallery: [
      {
        url: result.data?.parent_alternate_image || result.data?.image_url,
        position: 1,
      },
    ],
    small_image: {
      url: result.data?.parent_image_url || result.data?.image_url,
      label: result.data?.parent_name || result.value,
    },
    thumbnail: {
      url: result.data?.parent_image_url || result.data?.image_url,
      label: result.data?.parent_name || result.value,
    },
    url_key: result.data?.parent_url || result.data?.url,
    related_products: mapRelatedProducts(result.data?.parent_related_products_json || []),
    price_range: {
      minimum_price: mapPrice(
        'min',
        showVariation
          ? selectedVariation?.data?.price ?? result.data?.price
          : result.data?.parent_price,
        showVariation
          ? selectedVariation?.data?.special_price ?? result.data?.special_price
          : result.data?.parent_special_price,
        result.data?.currency_code,
        [],
      ),
      maximum_price: mapPrice(
        'max',
        showVariation
          ? selectedVariation?.data?.price ?? result.data?.price
          : result.data?.parent_price,
        showVariation
          ? selectedVariation?.data?.special_price ?? result.data?.special_price
          : result.data?.parent_special_price,
        result.data?.currency_code,
        [],
      ),
    },
    created_at: result.data?.parent_created_at,
    umsatz: result.data?.umsatz,
    // custom fields
    benuta_ar: result.data?.benuta_ar,
    benuta_form_new: result.data?.benuta_form_new_id,
    benuta_pile_material: result.data?.benuta_pile_material_id,
    benuta_test_seal: result.data?.benuta_test_seal_id,
    benuta_production_type: result.data?.benuta_production_type_id,
    benuta_waschbar: result.data?.benuta_waschbar_id,
    reviews_io_score: result.data?.parent_reviews_io_score,
    manufacturer: result.data?.manufacturer_id,
    // required fields
    rating_summary: 0,
    review_count: 0,
    reviews: { items: [], page_info: { total_pages: 0 } },
    staged: false,
    redirect_code: 0,
  }
}
