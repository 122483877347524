import { useMemo } from 'react'
import {
  checkHasSustainability,
  checkIsCotton,
  checkIsHandmade,
  checkIsWashable,
  checkIsWolle,
} from '~/lib/getProductFeature'

type ProductFeatures = {
  sealId: string
  productionTypeId: string
  pileMaterialId: number
  waschbarId: number
}

export const useProductFeatures = ({
  sealId,
  productionTypeId,
  pileMaterialId,
  waschbarId,
}: ProductFeatures) => {
  const featureList = {
    hasSustainability: checkHasSustainability(sealId),
    isHandmade: checkIsHandmade(productionTypeId),
    isCotton: checkIsCotton(pileMaterialId),
    isWolle: checkIsWolle(pileMaterialId),
    isWashable: checkIsWashable(waschbarId),
  }

  const isAnyFeature =
    featureList.isWolle ||
    featureList.isCotton ||
    featureList.hasSustainability ||
    featureList.isHandmade ||
    featureList.isWashable

  return { featureList, isAnyFeature }
}
