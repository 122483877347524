import { ConfigurableProduct } from '@magentoTypes'
import { useTranslation } from 'next-i18next'
import { useCallback } from 'react'
import { useProductAttributes } from '~/hooks/useProductAttributes'
import { isRugProduct } from '~/lib/isRugProduct'

export const useGenerateAltTags = () => {
  const { findLabel } = useProductAttributes()
  const { t } = useTranslation()

  const generateAltTags = useCallback(
    (product: ConfigurableProduct) => {
      return isRugProduct(product?.attribute_set_id)
        ? t(
            'Rug {{Placeholder_Collection_name}} made of {{Placeholder_Material}} in {{Placeholder_Colour}} by {{Placeholder_Subbrand}}',
            {
              Placeholder_Collection_name: findLabel({
                attributeCode: 'benuta_collection',
                attributeValue: product?.benuta_collection as string,
              }),
              Placeholder_Colour: findLabel({
                attributeCode: 'benuta_color_filter',
                attributeValue: product?.benuta_color_filter as number,
              }),
              Placeholder_Material: findLabel({
                attributeCode: 'benuta_pile_material',
                attributeValue: product?.benuta_pile_material as number,
              }),
              Placeholder_Subbrand: findLabel({
                attributeCode: 'manufacturer',
                attributeValue: product?.manufacturer as number,
              }),
            },
          )
        : t(
            '{{Placeholder_Product_Name}} in {{Placeholder_First_Element_of_Design}} design by {{Placeholder_Subbrand}}',
            {
              Placeholder_Product_Name: product?.name || '',
              Placeholder_First_Element_of_Design: findLabel({
                attributeCode: 'benuta_motive_design',
                attributeValue: product?.benuta_motive_design as string,
              }).split(',')?.[0],
              Placeholder_Subbrand: findLabel({
                attributeCode: 'manufacturer',
                attributeValue: product?.manufacturer as number,
              }),
            },
          )
    },
    [findLabel, t],
  )

  return { generateAltTags }
}
