import { useQueryClient } from '@tanstack/react-query'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { DEFAULT_LOCALE } from '~/config/constants'
import { createMagentoClient } from '~/graphql/magentoClient'
import { useProductCardDetailsQuery } from '@magentoTypes'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

export const useMagentoGetItemById = (
  sku: string,
  currentProductIndex: number,
  isEnabled: boolean,
) => {
  const router = useRouter()
  const queryClient = useQueryClient()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const magentoClient = createMagentoClient({ usePost: false, queryClient, locale: finalLocale })

  const { data, isLoading } = useProductCardDetailsQuery(
    magentoClient,
    { sku },
    { enabled: currentProductIndex !== 0 && isEnabled },
  )

  const getMagentoItemById = useCallback(() => {
    return { magentoRelatedProductData: data, isMagentoDataLoading: isLoading }
  }, [data, isLoading])

  return { getMagentoItemById }
}
