import { useMemo } from 'react'
import clsx from 'clsx'
import { ConfigurableProduct, ProductInterface, ProductImage } from '@magentoTypes'
import { Image } from '@next/image'
import { Link } from 'elements/Link/Link'

type ColorThumb = {
  url_key: string
  thumbnail: ProductImage
  id: string
}

type Props = {
  className?: string
  product: ConfigurableProduct
  relatedProducts: ProductInterface[]
  selectedColorIndex: number
  onColorSelect: (index: number) => void
}

export const ColorThumbs = ({
  className,
  product,
  relatedProducts,
  selectedColorIndex,
  onColorSelect,
}: Props) => {
  const combinedColors = useMemo(() => {
    const colors: ColorThumb[] = [
      {
        url_key: product.url_key || '',
        thumbnail: product.small_image || { url: '', label: '' },
        id: `main-${product.uid}`,
      },
    ]

    relatedProducts.forEach((related, index) => {
      if (related.stock_status !== 'OUT_OF_STOCK' && related.small_image) {
        colors.push({
          url_key: related.url_key || '',
          thumbnail: related.small_image,
          id: `related-${related.uid || index}`,
        })
      }
    })
    return colors.length > 3 ? colors.slice(0, 3) : colors
  }, [product, relatedProducts])

  if (combinedColors.length === 0) return null

  const extraColorsLength = Math.max(0, relatedProducts.length + 1 - 3)

  const handleColorSelect = (index: number) => {
    if (index === 0) {
      onColorSelect(0)
    } else {
      const currentIndex =
        product?.related_products?.findIndex(
          (el) => el?.url_key === combinedColors[index]?.url_key,
        ) || 0

      onColorSelect(currentIndex + 1)
    }
  }

  return (
    <ul
      className={clsx(
        'grid grid-cols-[repeat(4,_minmax(0,_26px))] justify-start gap-[5px] lgx:grid-cols-[repeat(4,_minmax(0,_40px))] lgx:gap-2.5',
        className,
      )}
    >
      {combinedColors.map(({ url_key, thumbnail, id }, index) => (
        <li
          key={id}
          className={clsx(
            'duration-400 relative aspect-square max-h-[26px] max-w-[26px] overflow-hidden rounded-[5px] border transition-all hover:border-sg-black lgx:max-h-[40px] lgx:max-w-[40px]',
            index === selectedColorIndex ? 'border-sg-black' : 'border-transparent',
          )}
        >
          <button
            title={url_key}
            className="relative block h-full w-full"
            onClick={() => handleColorSelect(index)}
          >
            {thumbnail.url && (
              <Image
                src={thumbnail.url}
                alt={thumbnail.label || ''}
                className="scale-[1.75] object-cover"
                width={50}
                height={50}
                placeholder="empty"
              />
            )}
          </button>
        </li>
      ))}
      {extraColorsLength > 0 && (
        <li className="self-center">
          <Link
            href={`${product.url_key}.html#${product.uid}`}
            title={product.url_key || ''}
            className="text-[15px] font-extralight"
          >
            + {extraColorsLength}
          </Link>
        </li>
      )}
    </ul>
  )
}
