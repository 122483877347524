import { useQuery } from '@tanstack/react-query'
import { useCioClient } from '~/hooks/useCioClient'
import { useCallback } from 'react'
import { ProductCardDetailsQuery } from '@magentoTypes'
import {
  GetBrowseResultsForItemIdsResponse,
  Result,
} from '@constructor-io/constructorio-client-javascript'
import { mapProduct } from '~/modules/SearchResultPageCio/lib/mapProduct'

export const useCioGetItemById = (
  itemId: string,
  cioForceShowVariation: boolean,
  isEnabled: boolean,
) => {
  const cioClient = useCioClient()

  const fetchCioItemById = async (): Promise<ProductCardDetailsQuery | null> => {
    try {
      const { response } = (await cioClient?.browse.getBrowseResultsForItemIds([itemId], {
        hiddenFields: ['parent_alternate_image', 'parent_attribute_set_id', 'size_id'],
        fmtOptions: {
          facet_groups_limit: 0,
          groups_max_depth: 0,
        },
      })) as GetBrowseResultsForItemIdsResponse
      // TODO: during product card refactor we should avoid this mapping
      const mappedResponse = {
        products: {
          items: [mapProduct(response?.results?.[0] as Result, cioForceShowVariation)],
        },
      }
      return mappedResponse
    } catch (error) {
      console.error('Error fetching product details:', error)
      return null
    }
  }

  const { data, isLoading } = useQuery({
    queryKey: ['cioItem', itemId],
    queryFn: fetchCioItemById,
    enabled: !!itemId && isEnabled,
  })

  const getCioItemById = useCallback(() => {
    return { cioRelatedProductData: data, isCioDataLoading: isLoading }
  }, [data, isLoading])

  return { getCioItemById }
}
